<template>
  <div class="page-card-demo  page-info-content">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getDate"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    />
    <div class="page-container-table">
      <div class="list-operation">
        <el-button type="primary" size="small" @click="handleAdd">
          添加
        </el-button>
      </div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getDate" />
    </div>
    <add-dialog :is-show-dialog="isShowDialog" :add-group-dialog-data="addGroupDialogData" :dialog-staus="dialogStaus" @getDate="getDate" @handleCancel="handleCancel" />
  </div>
</template>
<script>
import { selectGroupList } from '@/api/tradeFinancing'
import FormSearch from '@/components/FormSearch'
import Pagination from '@/components/Pagination2'
import Table from '@/components/Table.vue'
import AddDialog from './addDialog.vue'
export default {
  components: { FormSearch, Pagination, Table, AddDialog },
  data() {
    return {
      addGroupDialogData: {},
      dialogStaus: '1',
      isShowDialog: false,
      loading: false,
      listData: [],
      total: 0,
      formInline: {
        groupName: '', // 集团名称
        bankName: '' // 资金方名称
      },
      formItemArr: [
        { type: 'input', label: '集团名称', value: 'groupName' },
        { type: 'input', label: '资金方', value: 'bankName' }
      ],
      itemData: [
        { label: '集团名称', prop: 'groupName', width: 220 },
        { label: '资金方', prop: 'bankName', width: 120 },
        { label: '总额度(元)', prop: 'quotaSum', width: 180 },
        { label: '冻结额度(元)', prop: 'quotaDisabled', width: 180 },
        { label: '可用额度(元)', prop: 'quotaAbled', width: 180 },
        { label: '额度到期日', prop: 'quotaDue', width: 180 },
        { label: '已用额度(元)', prop: 'quotaUsed', width: 180 },
        { label: '额度状态', prop: 'quotaStat', child: [{ dictName: '有效', dictId: 0 }, { dictName: '失效', dictId: 1 }], width: 180 }
      ],
      operationButton: [
        { bType: 'text', label: '编辑', handleEvent: this.handleEdit },
        { bType: 'text', label: '详情', handleEvent: this.viewDetail }
      ]

    }
  },
  methods: {
    getDate(params) {
      this.loading = true
      this.formInline = {
        ...this.formInline,
        ...params
      }
      selectGroupList(this.formInline, res => {
        this.loading = false
        this.listData = res.data.list
        this.total = res.data.total
      }, () => {
        this.loading = false
      })
    },
    handleAdd() {
      const rowObj = {
        bank: {},
        group: {},
        groupUnicode: '', // 集团名称
        groupName: '',
        bankUnicode: '', // 资金方名称
        bankName: '',
        quotaSum: '', // 总额度
        quotaDue: '' // 额度到期日
      }
      this.openDialog('1', rowObj)
    },
    handleCancel(flag) {
      this.isShowDialog = flag
    },
    viewDetail({ id }) {
      this.$router.push({
        path: '/amountManage/groupQuotaManageDetail',
        query: { id }
      })
    },
    openDialog(str, objdata) {
      this.dialogStaus = str
      this.addGroupDialogData = objdata
      this.isShowDialog = true
    },
    // 编辑
    handleEdit(row) {
      const rowObj = {
        id: row.id,
        bank: { fundCmpName: row.bankName, fundCmpUnicode: row.bankUnicode },
        group: { coreCmpName: row.groupName, coreCmpUnicode: row.groupUnicode },
        groupUnicode: row.groupUnicode, // 集团名称
        groupName: row.groupName,
        bankUnicode: row.bankUnicode, // 资金方名称
        bankName: row.bankName,
        quotaSum: row.quotaSum, // 总额度
        quotaAbled: row.quotaAbled, // 可用额度
        quotaDisabled: row.quotaDisabled, // 冻结额度
        quotaUsed: row.quotaUsed, // 已用额度
        quotaDue: row.quotaDue // 额度到期日
      }
      this.openDialog('2', rowObj)
    }

  }
}
</script>
